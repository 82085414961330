import { ScreenClassProvider } from '@vp/swan'
import { PillarSlimBanner } from './PillarSlimBanner/PillarSlimBanner'
import { PromoSlimBanner } from './PromoSlimBanner/PromoSlimBanner'
import { SpacingWrapper } from './SpacingWrapper/SpacingWrapper'
import { useLogger } from '@vp/ubik-context'

export const Fragment = (props : any) => {
  const logger = useLogger()
  if (!props.pageData) {
    logger.warn('No page data found, returning null fragment')
    return <SpacingWrapper />
  }
  const bannerType = props.pageData.slimBannerType ?? false
  const promoData = {
    promoHeading: props.pageData.promoHeading || null,
    promoCode: props.pageData.promoCode || null,
    promoLegalText: props.pageData.promoLegalText || null,
    promoLegalLink: {
      text: props.pageData?.promoLink?.text || null,
      targetUrl: props.pageData?.promoLink?.targetUrl?.contentfulUrl || null
    },
    promoEndDate: props.pageData.promoEndDate || null,
    promoFirstCta: {
      text: props.pageData?.promoFirstCta?.text || null,
      targetUrl: props.pageData?.promoFirstCta?.targetUrl?.contentfulUrl || null
    },
    promoSecondCta: {
      text: props.pageData?.promoSecondCta?.text || null,
      targetUrl: props.pageData?.promoSecondCta?.targetUrl?.contentfulUrl || null
    },
    backgroundColor: props.pageData.backgroundColor || null,
  }
  const pillarData = [
    {
      icon: props.pageData.pillar1Icon || null,
      heading: props.pageData.pillar1Heading || null,
      description: props.pageData.pillar1Description || null,
    },
    {
      icon: props.pageData.pillar2Icon || null,
      heading: props.pageData.pillar2Heading || null,
      description: props.pageData.pillar2Description || null,
    },
    {
      icon: props.pageData.pillar3Icon || null,
      heading: props.pageData.pillar3Heading || null,
      description: props.pageData.pillar3Description || null,
    }
  ]

  return (
    <ScreenClassProvider>
      <SpacingWrapper>
        {bannerType ? <PillarSlimBanner pillarData={pillarData} /> : <PromoSlimBanner {...promoData} />}
      </SpacingWrapper>
    </ScreenClassProvider>
  )
}
