import { Pillar } from './Pillar'
import { Divider, FlexBox } from '@vp/swan'
import { PillarBannerProps } from '../types'

export const PillarRow = ({ pillarData }: PillarBannerProps) => {
  return (
    <>
      <FlexBox justifyContent='space-between' flexDirection='row' paddingX={7} paddingY={4}>
        {pillarData.map((pillar, index) => {
          const { icon, heading, description } = pillar
          if (icon && heading && description) {
            return (
              <Pillar
                key={index}
                icon={icon}
                heading={heading}
                description={description}
              />
            )
          }
          return null
        })}
      </FlexBox>
      <Divider />
    </>
  )
}
