import { Icon, Typography, FlexBox, Box } from '@vp/swan'
import { useSwanStyleKeys } from '@vp/ubik-context'
import { SWAN_STYLE_KEY_MAP } from '@vp/swan'
import { PillarProps } from '../types'

export const Pillar = ({ icon, heading, description } : PillarProps) => {
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.utility,
  ])

  return (
    <FlexBox alignItems='center'>
      <Box paddingY={4}>
        <FlexBox style={{ padding: '18px', borderRadius: '50%' }} justifyContent='center' alignItems='center' position='relative' bgc='accent'>
          <Icon iconType={icon} size='24p' />
        </FlexBox>
      </Box>
      <FlexBox flexDirection='column' paddingLeft={5}>
        <Typography fontSkin='title-subsection'>{heading}</Typography>
        <Typography fontSkin='body-standard'>{description}</Typography>
      </FlexBox>
    </FlexBox>
  )
}
