import { Carousel, CarouselSlide, Divider, FlexBox } from '@vp/swan'
import { Pillar } from './Pillar'
import { PillarBannerProps } from '../types'

export const PillarCarousel = ({ pillarData }: PillarBannerProps) => {
  return (
    <>
      <Carousel slidesToShow={1} progressIndicator='dots' arrows={false} paddingTop={4} paddingX={7}>
        {pillarData.map((pillar, index) => {
          const { icon, heading, description } = pillar
          if (icon && heading && description) {
            return (
              <CarouselSlide key={index}>
                <FlexBox justifyContent='center'>
                  <Pillar
                    icon={icon}
                    heading={heading}
                    description={description}
                  />
                </FlexBox>
              </CarouselSlide>
            )
          }
          return null
        })}
      </Carousel>
      <Divider />
    </>
  )
}
