import { PillarRow } from './PillarRow'
import { PillarCarousel } from './PillarCarousel'
import { useScreenClass } from '@vp/swan'
import { PillarBannerProps } from '../types'

export const PillarSlimBanner = ({ pillarData }: PillarBannerProps) => {
  const screenClass = useScreenClass()
  const isMobile = screenClass === 'xs'
  return (
    <>
      {isMobile ? <PillarCarousel pillarData={pillarData} /> : <PillarRow pillarData={pillarData} />}
    </>
  )
}
